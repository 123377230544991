<template>
  <div>
    <div class="row d-flex justify-content-center" v-if="model">
      <div class="col-md-5" v-if="model">
        <user-card :user="model"></user-card>
      </div>
      <div class="col-md-7">
        <div class="card" >
            <div class="card-header">
              Patient Contact Information Details
            </div>
            <div class="card-body">
              <div style="background-color: #ebecf1; height: 70vh; overflow-y: auto;"  v-if="model.infos.length > 0">
                <time-line type="simple">
                    <time-line-item inverted badge-type="info" badge-icon="nc-icon nc-alert-circle-i" v-for="(info, index) in model.infos" :key="index">
                      <div slot="header">
                        <small>Contacted Info created by <strong>{{ info.employee.employee_id }} - {{ info.employee.name }}</strong> | created on : <strong>{{ formattedDate(info.date, false) }}</strong></small> 
                      </div>
                      
                      <div slot="content" class="box">
                        {{ info.note }}
                      </div>
                      <small slot="footer"> <i class="nc-icon nc-watch-time"></i> last updated at {{ formattedDate(info.updated_at) }} </small>
                    </time-line-item>
                </time-line>
              </div>
              <p v-else> No Information recorded yet!</p>
            </div>
        </div>
      </div>
      <div class="col" :class="{ 'col-md-12': (model.infos.length > 0), 'col-md-12': (model.infos.length <= 0) }" v-if="(model.records.length > 0)" >
        <div class="card">
          <div class="card-header">Patient Records</div>
          <div class="card-body row d-flex">
            <div class="col-3" v-for="(record, index) in model.records" :key="index">
              <RecordBox :record="record"></RecordBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { TimeLine, TimeLineItem, Badge, RecordBox, UserCard } from "src/components/UIComponents";

import { mapGetters } from "vuex";
import pdf from 'vue-pdf'

export default {
  data() {
    return {
      wizardModel: {}
    };
  },
  components: {
    UserCard,
    RecordBox,
    TimeLine,
    TimeLineItem,
    pdf,
    [Badge.name]: Badge
  },
  mounted() {
    this.$store.dispatch("fetchPatient", this.$route.params.id);
  },

  computed: {
    ...mapGetters({
      model: "getPatient"
    }),
    
  },

  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete(validated, model) {
      this.$router.push(`/patients/list`);
    },
    onChange(prevIndex, nextIndex) {
      console.log("prev", prevIndex, "next", nextIndex);
    },
    handleView(url) {
      window.open(`${url}`, "_blank", );
    },
    formattedDate(date, isTime = true){
        var d = new Date(date);
        let options = (isTime) ? { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' } : { year: 'numeric', month: 'long', day: 'numeric' } ;
        return  d.toLocaleDateString('en-US', options); 
    }
  }
};
</script>
<style>
.card-timeline .timeline{
  padding: 20px 10px 20px 0px;
}
.card-timeline .timeline.timeline-simple > li > .timeline-panel{
  width: 90%;
}

.card-timeline .timeline-footer small {
    color: #9A9A9A;
    font-weight: 400;
    margin: 20px 0px 0px;
    display: inline-block;
}

.card-timeline .timeline-body .box{
  font-weight: bold;
  font-size: 1.25em;
}
</style>

